import AspectsSection from '@/components/AspectsSection';
import BlogPreviewSection from '@/components/BlogPreviewSection';
import ClientReviewsSection from '@/components/ClientReviewsSection';
import ClientVideosSection from '@/components/ClientVideosSection';
import FaqSection from '@/components/FaqSection';
import FilesSection from '@/components/FilesSection';
import HeroSection from '@/components/HeroSection';
import HowItWorksSection from '@/components/HowItWorksSection';
import MainPageBannerSection from '@/components/MainPageBannerSection';
import NewsletterSection from '@/components/NewsletterSection';
import OurProductsSection from '@/components/OurProductsSection';
import SignInSection from '@/components/SignInSection';
import WhatDoWeSellSection from '@/components/WhatDoWeSellSection';
import { modalIds } from '@/constants';
import Head from 'next/head';
import { useEffect } from 'react';

export default function Home() {
  useEffect(() => {
    // Funkcja sprawdzająca, czy ciastko o nazwie 'PomaranczeNewsletter' istnieje
    const checkCookie = (cookieName: string) => {
      return document.cookie
        .split(';')
        .some((cookie) => cookie.trim().startsWith(`${cookieName}=`));
    };

    // Funkcja do ustawiania ciastka
    const setCookie = (cookieName: string, value: string, days: number) => {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      document.cookie = `${cookieName}=${value}; expires=${date.toUTCString()}; path=/`;
    };

    // Jeżeli ciastko nie istnieje, wywołaj modal po 3 sekundach i ustaw ciastko
    if (!checkCookie('PomaranczeNewsletter')) {
      const timeout = setTimeout(() => {
        (
          document.getElementById(
            modalIds.newsletter,
          ) as HTMLDialogElement | null
        )?.showModal();

        // Ustawienie ciastka po wyświetleniu modala
        setCookie('PomaranczeNewsletter', 'true', 150); // Ciastko na 150 dni
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, []);

  return (
    <>
      <Head>
        <title>Strona główna</title>
        <meta name="description" content="Strona główna firmy Pomarańcze" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <HeroSection />
      <OurProductsSection />
      <MainPageBannerSection />
      <HowItWorksSection />
      <AspectsSection />
      <WhatDoWeSellSection />
      <SignInSection />
      <FaqSection />
      <BlogPreviewSection />
      <FilesSection />
      <ClientReviewsSection />
      <ClientVideosSection />
      <NewsletterSection />
    </>
  );
}
